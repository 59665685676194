import styled from 'styled-components'

export const InformationSection = styled.section`
  padding-top: 16px;
  & ul {
    margin-bottom: 16px;
  }
  & ul li {
    padding-bottom: 10px;
  }
`
